import React from 'react';
import './Footer.css';

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-content">
        <div className='d-flex justify-content-center aligin-items-center'>
          <div className="footer-logo">
          </div>
        </div>
        <div className="footer-address">
          <p>Tech Park, Nandyal, 518502, Andhra Pradesh, India</p>
          <p><a href="mailto:contact@complyzen.tech">contact@complyzen.tech</a></p>
        </div>
        <div className="footer-info d-flex align-items-center">
          <p>&copy; {new Date().getFullYear()} ComplyZen. All rights reserved.</p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
