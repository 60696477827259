import React from 'react';
import Lottie from 'react-lottie-player';
import lottieJson from './Background.json';
import './Background.css';

export default function Background() {
  return (
    <div className="background-container">
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: '100%', height: '100%' }}
      />
    </div>
  );
}
