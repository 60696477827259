import React, { useEffect } from 'react';
import { Helmet } from 'react-helmet'; // Import Helmet for managing meta tags
import { useNavigate } from 'react-router-dom';
import './Home.css';
import Jigel from '../animations/Jigel/Jigel';
import Space from '../animations/Space/Space';
import Assis from '../animations/Assis/Assis';
import Exp from '../animations/Exp/Exp';
import Sol from '../animations/Sol/Sol';
import Background from '../animations/Background/Background';
import ScrollToTopButton from './ScrollToTopButton';
import Typing from 'react-typing-effect';

const highlights = [
  "Custom Software Development",
  "Machine Learning and Neural Networks",
  "Cloud Services and App Development"
];

const features = [
  {
    title: "Scalable Solutions",
    description: "Our solutions are designed to grow with your business, ensuring long-term success.",
    component: <Sol />
  },
  {
    title: "Expert Team",
    description: "Our team of experts is dedicated to delivering top-notch services tailored to your needs.",
    component: <Exp />
  },
  {
    title: "24/7 Support",
    description: "We offer round-the-clock support to ensure your operations run smoothly without any interruptions.",
    component: <Assis />
  }
];

const testimonials = [
  {
    text: "ComplyZen transformed our operations with their innovative solutions. Highly recommend!",
    author: "- Joy Boy"
  },
  {
    text: "The team at ComplyZen provided exceptional service and support throughout our project.",
    author: "- Sai"
  },
  {
    text: "Their expertise in compliance solutions is unmatched. We couldn’t be happier with the results.",
    author: "- Khaleel Ahmed"
  }
];

const Home = () => {
  const navigate = useNavigate();

  const handleContactClick = () => {
    navigate('/contact-us', { state: { scrollToTop: true } });
  };

  useEffect(() => {
    // Adding JSON-LD structured data for SEO
    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://www.complyzen.tech",
      "logo": "https://complyzen.tech/logo192.png",
      "name": "ComplyZen",
      "description": "ComplyZen offers custom software development, machine learning solutions, and cloud services to help businesses innovate and succeed.",
      "sameAs": [
        "https://www.linkedin.com/company/complyzen",
        "https://twitter.com/complyzen"
      ],
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 9966168760",
        "contactType": "Customer Service",
        "areaServed": "IN",
        "availableLanguage": "English"
      }
    });
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  return (
    <div className="home-container">
      <Helmet>
        <title>ComplyZen - Innovate, Compete, Succeed</title>
        <meta name="description" content="ComplyZen provides cutting-edge tech solutions in custom software development, machine learning, and cloud services. Partner with us to drive innovation and achieve business success." />
        <meta name="keywords" content="ComplyZen, Custom Software Development, Machine Learning, Cloud Services, IT Solutions" />
        <meta property="og:title" content="ComplyZen - Innovate, Compete, Succeed" />
        <meta property="og:description" content="ComplyZen offers expert IT services in custom software, machine learning, and cloud development. Let's innovate together." />
        <meta property="og:image" content="https://complyzen.tech/logo192.png" />
        <meta property="og:url" content="https://www.complyzen.tech" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ComplyZen - Innovate, Compete, Succeed" />
        <meta name="twitter:description" content="ComplyZen provides expert IT solutions to help businesses grow and thrive in a competitive market." />
        <meta name="twitter:image" content="https://complyzen.tech/logo192.png" />
      </Helmet>

      {/* Hero Section */}
      <section className="hero-section d-flex">
        <div className="hero-content">
          <h1 className="hero-text">
            Ignite IT, Ace The <br /><span className="text-color-red">
              <Typing
                text={['Future.','Competition.','Innovation.']}
                speed={100}
                eraseSpeed={10}
                eraseDelay={2000}
                typingDelay={1000}
              />
            </span>
          </h1>
          <button className="hero-button" onClick={() => {
            navigate('/about-us')
          }}>
            Learn More
          </button>
        </div>
        <Jigel />
      </section>

      {/* Introduction Section */}
      <section className="intro-section">
        <p className="intro-text">
          At ComplyZen, we provide cutting-edge tech solutions to drive innovation and empower businesses.
        </p>
        <div className='d-flex justify-content-around'>
          <Space />
          <div className="highlights">
            {highlights.map((highlight, index) => (
              <div key={index} className="highlight">
                {highlight}
              </div>
            ))}
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section className="features-section">
        <h2 className="features-heading">
          Our Features
        </h2>
        <div className="features-list">
          {features.map((feature, index) => (
            <div key={index} className="feature">
              <h3>{feature.title}</h3>
              <p>{feature.description}</p>
              <div className="center-component">
                {feature.component}
              </div>
            </div>
          ))}
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="testimonials-section">
        <Background />
        <h2 className="testimonials-heading">
          What Our Clients Say
        </h2>
        <div className="testimonials">
          {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial">
              <p>{testimonial.text}</p>
              <span>{testimonial.author}</span>
            </div>
          ))}
        </div>
      </section>

      {/* Call to Action Section */}
      <section className="cta-section">
        <h2 className="cta-heading">
          Ready to Get Started?
        </h2>
        <p className="cta-text">
          Contact us today to learn more about how we can help your business thrive.
        </p>
        <button className="cta-button" onClick={handleContactClick}>
          Contact Us
        </button>
      </section>

      {/* Scroll to Top Button */}
      <ScrollToTopButton />
    </div>
  );
};

export default Home;
