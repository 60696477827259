import React, { useEffect, useState } from 'react';
import emailjs from 'emailjs-com'; // Import EmailJS
import toast, { Toaster } from 'react-hot-toast'; // Import React Hot Toast
import './Contact.css';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    message: ''
  });

  const [submitted, setSubmitted] = useState(false);
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });

    const script = document.createElement('script');
    script.type = 'application/ld+json';
    script.innerHTML = JSON.stringify({
      "@context": "https://schema.org",
      "@type": "Organization",
      "url": "https://www.complyzen.tech",
      "logo": "https://complyzen.tech/logo192.png",
      "contactPoint": {
        "@type": "ContactPoint",
        "telephone": "+91 9966168760",
        "contactType": "Customer Service",
        "areaServed": "IN",
        "availableLanguage": "English"
      }
    });
    document.head.appendChild(script);

    return () => {
      document.head.removeChild(script);
    };
  }, []);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    const serviceID = 'service_ky695zr';
    const templateID = 'template_g09vqdu';
    const userID = 'nc6iajeENZHed2bqX';

    emailjs.send(serviceID, templateID, formData, userID)
      .then((response) => {
        toast.success('Message sent successfully!');
        setSubmitted(true);
        setFormData({
          name: '',
          email: '',
          message: ''
        });
      })
      .catch((error) => {
        console.error('Error sending email:', error);
        toast.error('There was an error sending your message. Please try again later.');
        setError('There was an error sending your message. Please try again later.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <div className="contact-container">
<section className="contact-info">
  <h1 className="contact-heading">Contact Us</h1>
  <p className="contact-description">
    We’d love to hear from you! Whether you have a question, feedback, or just want to connect, feel free to reach out to us.
  </p>
  <div className="contact-details">
    <div className="contact-detail">
      <h2 className="detail-title">Chief Executive Officer</h2>
      <p className="detail-text">Lohith Reddy</p>
    </div>
    <div className="contact-detail">
      <h2 className="detail-title">Address</h2>
      <p className="detail-text">Tech Park, Nandyal, 518502, Andhra Pradesh, India</p>
    </div>
    <div className="contact-detail">
      <h2 className="detail-title">Phone</h2>
      <p className="detail-text">
        <a href="tel:+919966168760" className="contact-link">+91 9966168760</a>
      </p>
    </div>
    <div className="contact-detail">
      <h2 className="detail-title">Email</h2>
      <p className="detail-text">
        <a href="mailto:contact@complyzen.tech" className="contact-link">contact@complyzen.tech</a>
      </p>
    </div>
  </div>
</section>


      <section className="contact-form">
        <h2 className="form-heading">Send Us a Message</h2>
        {loading && <div className="loading-screen">Sending...</div>}
        <form onSubmit={handleSubmit}>
          <label htmlFor="name" className="form-label">Name</label>
          <input
            type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleChange}
            className="form-input"
            required
            disabled={loading}
          />

          <label htmlFor="email" className="form-label">Email</label>
          <input
            type="email"
            id="email"
            name="email"
            value={formData.email}
            onChange={handleChange}
            className="form-input"
            required
            disabled={loading}
          />

          <label htmlFor="message" className="form-label">Message</label>
          <textarea
            id="message"
            name="message"
            value={formData.message}
            onChange={handleChange}
            className="form-textarea"
            required
            disabled={loading}
          ></textarea>
          <div className='d-flex justify-content-end'>

            <button type="submit" className="form-button" disabled={loading}>
              {loading ? 'Sending...' : 'Send Message'}
            </button>
          </div>
        </form>
        {submitted && <p className="form-success">Thank you for reaching out! We will get back to you soon.</p>}
        {error && <p className="form-error">{error}</p>}
      </section>
      <Toaster />
    </div>
  );
};

export default Contact;
