import React, { useEffect } from 'react';
import './About.css';
import { Helmet } from 'react-helmet';

// Array for mission content
const mission = [
  "Our mission is to empower businesses to achieve compliance with confidence. We believe that compliance shouldn't be a burden, but rather a competitive advantage. With ComplyZen, you can focus on what matters most - growing your business and serving your customers."
];

// Array for values content
const values = [
  {
    title: 'Innovation',
    text: 'We\'re committed to staying ahead of the curve and developing cutting-edge solutions that meet the evolving needs of our customers.'
  },
  {
    title: 'Customer-Centricity',
    text: 'We put our customers at the heart of everything we do, delivering exceptional service and support every step of the way.'
  },
  {
    title: 'Integrity',
    text: 'We operate with transparency, honesty, and integrity, upholding the highest standards of ethics and compliance in everything we do.'
  }
];

const About = () => {
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="about-container">
            <Helmet>
        <title>ComplyZen - Innovate, Compete, Succeed</title>
        <meta name="description" content="ComplyZen provides cutting-edge tech solutions in custom software development, machine learning, and cloud services. Partner with us to drive innovation and achieve business success." />
        <meta name="keywords" content="ComplyZen, Custom Software Development, Machine Learning, Cloud Services, IT Solutions" />
        <meta property="og:title" content="ComplyZen - Innovate, Compete, Succeed" />
        <meta property="og:description" content="ComplyZen offers expert IT services in custom software, machine learning, and cloud development. Let's innovate together." />
        <meta property="og:image" content="https://complyzen.tech/logo192.png" />
        <meta property="og:url" content="https://www.complyzen.tech" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ComplyZen - Innovate, Compete, Succeed" />
        <meta name="twitter:description" content="ComplyZen provides expert IT solutions to help businesses grow and thrive in a competitive market." />
        <meta name="twitter:image" content="https://complyzen.tech/logo192.png" />
      </Helmet>

      <section className="about-intro">
        <h1 className="about-heading">About ComplyZen</h1>
        <p className="about-description">
          At ComplyZen, we're passionate about helping businesses simplify their regulatory compliance processes. Our software solutions are designed to streamline your workflow, reduce risk, and increase efficiency.
        </p>
      </section>

      <section className="mission-section">
        <h2 className="mission-heading">Our Mission</h2>
        {mission.map((text, index) => (
          <p key={index} className="mission-text">{text}</p>
        ))}
      </section>

      <section className="values-section">
        <h2 className="values-heading">Our Values</h2>
        <div className="values-list">
          {values.map((value, index) => (
            <div key={index} className="value-item">
              <h3 className="value-title">{value.title}</h3>
              <p className="value-text">{value.text}</p>
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default About;
