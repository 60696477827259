import React, { useEffect } from 'react';
import './Services.css';
import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';


// Array of service data
const services = [
  {
    title: 'Custom Software Development',
    details: 'We deliver bespoke software solutions tailored to your specific business needs, enhancing operational efficiency and effectiveness.'
  },
  {
    title: 'IT Consulting',
    details: 'Our expert consultants provide strategic guidance to optimize your IT infrastructure, drive innovation, and align technology with business objectives.'
  },
  {
    title: 'Compliance Solutions',
    details: 'We offer comprehensive compliance services to ensure your business adheres to industry regulations and standards, mitigating risks and ensuring operational integrity.'
  },
  {
    title: 'SEO',
    details: 'Our SEO strategies are designed to boost your website’s visibility and ranking on search engines, driving targeted organic traffic and increasing your online presence.'
  },
  {
    title: 'Machine Learning',
    details: 'We implement advanced machine learning models to analyze complex data, derive actionable insights, and automate decision-making processes for better business outcomes.'
  },
  {
    title: 'Neural Networks',
    details: 'Our neural network solutions leverage cutting-edge algorithms to model intricate patterns, enabling sophisticated data analysis and predictive capabilities.'
  },
  {
    title: 'Cloud Services',
    details: 'We provide scalable and flexible cloud solutions that enhance your business’s agility, optimize resource utilization, and reduce operational costs.'
  },
  {
    title: 'App Development',
    details: 'We create high-performance applications for both mobile and desktop platforms, tailored to meet your unique business requirements and deliver a superior user experience.'
  },
  {
    title: 'Web Development',
    details: 'Our web development services focus on building responsive, user-friendly websites that drive engagement, support business growth, and enhance your digital footprint.'
  }
];



const Services = () => {
  const navigate = useNavigate()
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className="services-container">
      <Helmet>
        <title>ComplyZen - Innovate, Compete, Succeed</title>
        <meta name="description" content="ComplyZen provides cutting-edge tech solutions in custom software development, machine learning, and cloud services. Partner with us to drive innovation and achieve business success." />
        <meta name="keywords" content="ComplyZen, Custom Software Development, Machine Learning, Cloud Services, IT Solutions" />
        <meta property="og:title" content="ComplyZen - Innovate, Compete, Succeed" />
        <meta property="og:description" content="ComplyZen offers expert IT services in custom software, machine learning, and cloud development. Let's innovate together." />
        <meta property="og:image" content="https://complyzen.tech/logo192.png" />
        <meta property="og:url" content="https://www.complyzen.tech" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="ComplyZen - Innovate, Compete, Succeed" />
        <meta name="twitter:description" content="ComplyZen provides expert IT solutions to help businesses grow and thrive in a competitive market." />
        <meta name="twitter:image" content="https://complyzen.tech/logo192.png" />
      </Helmet>

      <section className="services-intro">
        <h1 className="services-heading">Our Services</h1>
        <p className="services-description">
          Discover our wide range of services designed to cater to your unique business needs.
        </p>
      </section>

      <section className="services-list">
        {services.map((service, index) => (
          <div key={index} className="service-card">
            <h2 className="service-title">{service.title}</h2>
            <p className="service-details">{service.details}</p>
          </div>
        ))}
      </section>

      <section className="cta-section">
        <h2 className="cta-heading">Ready to Transform Your Business?</h2>
        <p className="cta-text">
          Contact us today to discuss how we can help you achieve your goals with our expert services.
        </p>
        <button className="cta-button" onClick={() => { navigate('/contact-us') }}>Get in Touch</button>
      </section>
    </div>
  );
};

export default Services;
