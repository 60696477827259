import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Navbar, Nav, Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import './Navbar.css';

const NavBar = () => {
  // State to control Navbar collapse
  const [isNavExpanded, setIsNavExpanded] = useState(false);

  // Handler to toggle Navbar collapse
  const handleNavLinkClick = () => {
    if (window.innerWidth <= 768) {
      setIsNavExpanded(false);
    }
  };

  return (
    <Navbar
      bg="dark"
      variant="dark"
      expand="lg"
      fixed="top"
      className="navbar"
      expanded={isNavExpanded}
    >
      <Container>
        <Navbar.Brand as={Link} to="/" className="footer-logo">
        
        </Navbar.Brand>
        <Navbar.Toggle
          aria-controls="basic-navbar-nav"
          className="navbar-toggle"
          onClick={() => setIsNavExpanded(!isNavExpanded)}
        >
          <FontAwesomeIcon icon={faBars} className="navbar-toggler-icon" />
        </Navbar.Toggle>
        <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="ml-auto navbar-nav">
            <Nav.Link as={Link} to="/" className="navbar-item" onClick={handleNavLinkClick}>Home</Nav.Link>
            <Nav.Link as={Link} to="/services" className="navbar-item" onClick={handleNavLinkClick}>Services</Nav.Link>
            <Nav.Link as={Link} to="/projects" className="navbar-item" onClick={handleNavLinkClick}>Projects</Nav.Link>
            <Nav.Link as={Link} to="/about-us" className="navbar-item" onClick={handleNavLinkClick}>About</Nav.Link>
            <Nav.Link as={Link} to="/contact-us" className="navbar-item" onClick={handleNavLinkClick}>Contact Us</Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  );
};

export default NavBar;
