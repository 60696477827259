import Lottie from 'react-lottie-player'
import lottieJson from './Space.json'

export default function Space() {
    return (
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width: 250, height: 250 }}
      />
    )
  }