import Lottie from 'react-lottie-player'
import lottieJson from './Sol.json'
import { useEffect, useState } from 'react'

export default function Jigel() {
  const [height,setHeight]=useState(200)
  useEffect(()=>{
    if(window.screen.width<768){
      setHeight(150)
    }
  },[])
    return (
      <Lottie
        loop
        animationData={lottieJson}
        play
        style={{ width:height, height}}
      />
    )
  }