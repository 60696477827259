import { BrowserRouter, Route, Routes } from "react-router-dom";
import About from "./components/About/About";
import Projects from "./components/Projects/Projects";
import Footer from "./components/Footer/Footer";
import Home from "./components/Home/Home";
import 'bootstrap/dist/css/bootstrap.min.css';
import 'mdb-react-ui-kit/dist/css/mdb.min.css';
import "@fortawesome/fontawesome-free/css/all.min.css";
import Services from "./components/Services/Services";
import './App.css';
import Contact from "./components/Contact/Contact";
import NavBar from "./components/Navbar/Navbar";
import CustomCursor from "./components/CustomCursor/CustomCursor"; // Import the custom cursor

function App() {
  return (
    <BrowserRouter>
      <NavBar />
      <CustomCursor /> {/* Include the custom cursor */}
      <div className="b-down">
        <Routes>
          <Route path="/about-us" element={<About />} />
          <Route path="/services" element={<Services />} />
          <Route path="/projects" element={<Projects />} />
          <Route path="/contact-us" element={<Contact />} />
          <Route path="/*" element={<Home />} />
        </Routes>
        <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
